import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BILLING_FEATURE_BASE_PRICE,
  BILLING_FEATURE_COST_PER_LEAD_MAX,
  BILLING_FEATURE_CUSTOM_CONFIG,
  BILLING_FEATURE_LIFT_BUILDING_PRICES,
  BILLING_FEATURE_MANAGED_EXTERNALLY,
  BILLING_FEATURE_MIN_DURATION,
  BILLING_FEATURE_PROPERTY_LIST,
  BILLING_FEATURE_SUPERSYNC_URL,
  BILLING_FEATURE_VARIABLE_BASE_PRICE,
  TValidBillingFeature,
  assertValidBillingType,
  billingTypeHasFeature,
  getBillingFeatureMetadata,
  getBillingFrequencies,
} from '@llws/hydra-shared';

import { getOne, getMany } from "src/redux/features/dynamic/actions";
import { useAppSelector } from "src/redux/hooks";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Button,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { ClientDropdown } from "src/components/parts/ClientDropdown";
import { BaseSubscriptionDropdown } from "src/components/parts/BaseSubscriptionDropdown";
import { HHDatePicker } from "src/components/parts/HHDatePicker";
import { floatVal, intVal } from "@jamesgmarks/utilities";
import { HHConfigEditor } from "src/components/parts/HHConfigEditor";
import { } from "typeorm";
import { makeYmd } from "src/app-utils";
import { CommissionEditor } from "./EditorComponents/CommissionEditor";
import { assertIsISubscriptionVariableBasePriceEditable, SubscriptionEditable } from "./EditorComponents/EditableTypes";
import { SubscriptionsHasBuildingsEditor } from "./EditorComponents/SubscriptionsHasBuildingsEditor";
import { VariableBasePriceEditor } from "./EditorComponents/VariableBasePriceEditor";

export const SubscriptionEditor = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const storeSubscription = useAppSelector((store) => store.dynamic.data.subscriptions?.single);
  const billingFrequencies = useAppSelector((store) => store.dynamic.data.billing_frequencies?.list);
  const [createMode, setCreateMode] = useState(true);

  const defaultSubscription :SubscriptionEditable= {
    parentSubscriptionId: 0,
    baseSubscriptionId: 0,
    clientId: 0,
    partnerCut: '0',
    billingFrequencyId: 0,
    handleBilling: 0,
    salesRepId: 0,
    startDate: makeYmd(new Date()),
    expiryDate: null,
    firstFiscalMonth: 0,
    basePrice: '0',
    basePriceScalar: '0',
    discountRate: '0',
    evenBuildingSplit: 0,
    minBuildingsForCommission: 0,
    takeoverDate: null,
    parentSalesRepId: 0,
    parentSalesRepCut: '0',
    revenueTrackingCode: null,
    commissionRate: '0',
    commissionExpiryDate: null,
    totalCommissionRate: '0',
    quoteFilename: null,
    notes: [],
    costPerLeadMax: '0',
    invoiceDescription: '',
    poNumber: '',
    quoteId: null,
    rateExpiryDate: '',
    minPropertiesForRate: 0,
    maxPropertiesForRate: 0,
    customConfiguration: null,
    minDuration: 0,
    createdUserId: 0,
    modifiedUserId: 0,
    subscriptionsHasBuildings: [],
  };

  const [ subscription, setSubscription ] = useState<SubscriptionEditable | null>(
    storeSubscription as unknown as SubscriptionEditable ?? defaultSubscription,
  );
  const [ billingZonesState, setBillingZoneStates ] = useState<null>(
    null,
  );

  const setSubscriptionProp = useCallback(
    <T extends keyof SubscriptionEditable>(prop: T, newVal: SubscriptionEditable[T]) => {
      if (!subscription) return;
      setSubscription({ ...subscription, [prop]: newVal });
    }, [ subscription, setSubscription ],
  );
  useEffect(() => {
    if(!createMode){
      console.log('setting subscription', createMode, storeSubscription);
      setSubscription(storeSubscription as unknown as SubscriptionEditable);
    }
  }, [ storeSubscription, createMode ]);

  useEffect(() => {

    if(!subscriptionId){
      setCreateMode(true);
      setSubscription(defaultSubscription);
    }
    if(subscriptionId){
      setCreateMode(false);
      console.log({createMode});
      console.log('this gets called');
      console.log({subscriptionId});
      getOne('subscriptions', subscriptionId, [
        { path: 'client', alias: 'c' },
        { path: 'variableBasePriceOverrides', alias: 'vbpo'},
        { path: 'billingZoneCityOverrides', alias: 'bzco'},
        { path: 'bzco.ownershipGroup', alias: 'bzcoog'},
        { path: 'bzco.city', alias: 'bzcogc' },
        { path: 'bzcogc.province', alias: 'bzcogp' },
        { path: 'baseSubscription', alias: 'bs' },
        { path: 'bs.variableBasePrices', alias: 'vbp'},
        { path: 'bs.billingZones', alias: 'bz' },
        { path: 'bz.billingZoneCities', alias: 'bzc' },
        { path: 'bzc.city', alias: 'gc' },
        { path: 'gc.province', alias: 'gp' },
        { path: 'bs.service', alias: 'svc' },
        { path: 'bs.partner', alias: 'p' },
        { path: 'subscriptionsHasBuildings', alias: 'shb' },
        { path: 'shb.building', alias: 'shbb' },
        { path: 'parentSubscription', alias: 'ps' },
        { path: 'workInProgressQueues', alias: 'wipqs' },
        { path: 'wipqs.workInProgress', alias: 'wip' },
        { path: 'salesRep', alias: 'sr' },
        { path: 'parentSalesRep', alias: 'psr' },
        { path: 'sr.user', alias: 'u' },
        { path: 'psr.user', alias: 'pu' },
        { path: 'commissionSchemes', alias: 'cs' },
      ]);
      getMany('billing_frequencies');
    }
  }, [ subscriptionId, createMode ]);

  const billingFeatureMetadata = useCallback((billingTypeId: number, feature: TValidBillingFeature, field: string) => (
    (getBillingFeatureMetadata(assertValidBillingType(billingTypeId), feature as any) as any)[field] //TODO: get rid of any
  ), []);

  const accountType = useMemo(() => (subscription?.handleBilling ? 'client' : 'partner'), [ subscription ]);

  const subscriptionFeeType = useMemo(
    () => subscription?.billingFrequencyId === 1 ? 'ONE_TIME' : 'RECURRING',
    [ subscription ],
  );

  // const wipQueue = useMemo(
  //   () => subscription?.workInProgressQueues?.[0],
  //   [ subscription ],
  // );

  const billingTypeId = useMemo(() => (subscription?.baseSubscription?.service?.billingTypeId), [ subscription ]);

  const billingFrequenciesOptions = useMemo(() => {
    if (billingTypeId) {
      const availableFrequencies = getBillingFrequencies(billingTypeId);
      return availableFrequencies.map((bf) => (
        {
          value: bf,
          label: billingFrequencies?.find((billingFrequncy) => billingFrequncy.id === bf)?.name ?? 'Not Available',
        }
      ));
    } return [];
  }, [billingFrequencies, billingTypeId]);

  if (billingTypeId===null || billingTypeId===undefined || subscription === null || subscription === undefined) {
    console.log({ billingTypeId, subscription });
    return <>Loading...</>;
  }

  if (billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_MANAGED_EXTERNALLY)) {
    return <Grid container spacing={2} mt={2} mr={2}  justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Paper variant='outlined' sx={{padding: '1rem', backgroundColor: '#e0e0e0'}}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <p> This feature is managed somewhere else</p>
            {
              billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_SUPERSYNC_URL)
              && <>

                <a href={billingFeatureMetadata(billingTypeId, BILLING_FEATURE_SUPERSYNC_URL, 'url')}>
                  Go to SuperSync
                </a>
              </>
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>;
  }

  return <>
    <Grid container spacing={2} mt={2} mr={2}  justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Paper variant='outlined' sx={{padding: '1rem', backgroundColor: '#e0e0e0'}}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <strong>Subscription Editor ({billingTypeId})</strong>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <div>Account:</div>
              <div>
                <ClientDropdown
                  clientId={subscription.clientId ?? null}
                  onClientChanged={(client) => {
                    if (client && (client.id !== subscription.clientId)) {
                      setSubscriptionProp('clientId', client.id);
                    }
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <BaseSubscriptionDropdown
                label='Product'
                baseSubscriptionId={subscription.baseSubscriptionId ?? null}
                onBaseSubscriptionChanged={(bs) => {
                  // TODO: Handle null selection.
                  if (bs?.id) { setSubscriptionProp('baseSubscriptionId', bs.id); }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'PO Number'}
                type="text"
                sx={{background: "white"}}
                size="small"
                value={subscription.poNumber ?? ''}
                onChange={(e) => setSubscriptionProp('poNumber', e.target.value || undefined)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'Quote ID'}
                type="text"
                sx={{background: "white"}}
                size="small"
                value={subscription.quoteId ?? ''}
                onChange={(e) => setSubscriptionProp('quoteId', e.target.value || null)}
              />
            </Grid>
            <Grid item xs={6}>
              <HHDatePicker
                label="Rate Expiry Date"
                value={new Date(subscription.rateExpiryDate ?? '')}
                onChange={(d: Date | null) => { if (d) { setSubscriptionProp('rateExpiryDate', makeYmd(d)); } }}
              />

            </Grid>
            <Grid item xs={6}>
              Quote: <input type="file" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Min Properties Rate"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                sx={{background: "white"}}
                size="small"
                value={subscription.minPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('minPropertiesForRate', e.target.value ? floatVal(e.target.value) : null)
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Max Properties Rate"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                sx={{background: "white"}}
                size="small"
                value={subscription.maxPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('maxPropertiesForRate', e.target.value ? floatVal(e.target.value) : null)
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Invoice Description"
                type="text"
                sx={{background: "white"}}
                size="small"
                fullWidth
                value={subscription.invoiceDescription}
                onChange={(e) => setSubscriptionProp('invoiceDescription', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} >
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BASE_PRICE)
                && <Grid item container>
                  <Grid item xs={6}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{paddingRight: '1rem'}}>Base Price: </label>
                      <TextField
                        label={
                          billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'label') ?? 'Base Price'
                        }
                        type="number"
                        sx={{background: "white"}}
                        size="small"
                        value={`${subscription.basePrice ?? ''}`}
                        onChange={(e) => setSubscriptionProp('basePrice', e.target.value || '0')}
                        disabled={
                          billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'condition')
                            ? billingFeatureMetadata(
                              billingTypeId, BILLING_FEATURE_BASE_PRICE, 'condition',
                            )(subscription)
                            : false

                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </span>
                  </Grid>
                  {
                    //TODO: should update the config
                    !!billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'hasScalar')
                && <Grid item xs={6}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{paddingRight: '1rem'}}>Base Price Scalar: </label>
                    <TextField
                      label={billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'scalarLabel')}
                      type="number"
                      sx={{background: "white", marginRight: '0.5rem'}}
                      inputProps={{
                        step: 0.1,
                      }}
                      size="small"
                      value={`${subscription.basePriceScalar ?? ''}`}
                      onChange={(e) => setSubscriptionProp('basePriceScalar', e.target.value || '0')}
                    />
                    <Tooltip
                      title={
                        `Base Price(${subscription.basePrice}) * Base Price Scalar(${subscription.basePriceScalar}) =
                         ${(floatVal(subscription.basePrice ?? 0) * floatVal(subscription.basePriceScalar)).toFixed(2)}`
                      }
                      placement='right'
                    >
                      <InfoIcon />
                    </Tooltip>
                  </span>
                </Grid>
                  }

                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              { getBillingFrequencies(billingTypeId).length > 0
             &&
              <FormControl sx={{width: '50%', background: "white", marginRight: '0.5rem'}}>
                <InputLabel id="billing-frequency-label">Billing Frequency</InputLabel>
                <Select
                  labelId="billing-frequency-label"
                  value={subscription.billingFrequencyId}
                  onChange={e => setSubscriptionProp('billingFrequencyId', intVal(e.target.value))}
                  label="Billing Frequency"
                  size="small"
                >
                  {billingFrequenciesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              }
            </Grid>
            <Grid item xs={12}>
              <CommissionEditor subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
            </Grid>
            <Grid item xs={12}>

              {/* <BillingZoneEditor subscription={subscription} setSubscriptionProp={setSubscriptionProp} /> */}
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_COST_PER_LEAD_MAX)
                && <>
                  <TextField
                    label="Cost Per Lead Max"
                    type="number"
                    inputProps={{
                      step: 0.01,
                    }}
                    sx={{background: "white"}}
                    size="small"
                    value={`${subscription.costPerLeadMax ?? ''}`}
                    onChange={(e) => setSubscriptionProp('costPerLeadMax', e.target.value || '0')}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  />
                </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_VARIABLE_BASE_PRICE)
                && <VariableBasePriceEditor
                  subscription={assertIsISubscriptionVariableBasePriceEditable(subscription)}
                  setSubscriptionProp={setSubscriptionProp}
                />
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_MIN_DURATION)
                    && <>
                      <TextField
                        label="Min Duration"
                        type="number"
                        inputProps={{
                          step: 0.01,
                        }}
                        sx={{background: "white"}}
                        size="small"
                        value={`${subscription.minDuration ?? ''}`}
                        onChange={(e) => setSubscriptionProp('minDuration', intVal(e.target.value || '0'))}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      /></>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_LIFT_BUILDING_PRICES)
        && <>
          <TextField
            label="Min Duration"
            type="number"
            inputProps={{
              step: 0.01,
            }}
            sx={{background: "white"}}
            size="small"
            value={`${subscription.liftSubscriptions?.costPerSingleFamilyHome ?? ''}`}
            onChange={(e) => (
              // setSubscriptionProp(
              //   'liftSubscriptions',
              //   {...subscription.liftSubscriptions, costPerSingleFamilyHome: (e.target.value || '0')},
              // )
              console.log('test')
            )}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />
          <TextField
            label="Min Duration"
            type="number"
            inputProps={{
              step: 0.01,
            }}
            sx={{background: "white"}}
            size="small"
            value={`${subscription.liftSubscriptions?.costPerMultiFamilyHome ?? ''}`}
            onChange={(e) => (
              // setSubscriptionProp(
              // 'liftSubscriptions',
              // {...subscription.liftSubscriptions, costPerMultiFamilyHome: (e.target.value || '0')},
              console.log('test')
            )}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />

        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_PROPERTY_LIST)
                && <SubscriptionsHasBuildingsEditor
                  subscription={subscription ?? defaultSubscription }
                  setSubscriptionProp={(field: any, data: any) => setSubscriptionProp(field, data)} //TODO: get rid of any
                />
              }
            </Grid>
            <Grid item xs={12}>
              {
                !billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_CUSTOM_CONFIG)
        && <HHConfigEditor
          customConfig={subscription.customConfiguration ?? {}}
          onUpdate={(data) => setSubscriptionProp('customConfiguration', data)}
        />
              }
            </Grid>

            {/* <pre>
              {JSON.stringify(subscription, null, 2)}
            </pre> */}
          </Grid>
          <hr/>
          <Button
            variant="contained"
            onClick={(e) => {
              alert('i didnt really save anything');
            }}
          >Save Subscription</Button>
          <Button
            variant="contained"
            onClick={(e) => {
              alert('i didnt really cancel anything');
            }}
          >Cancel</Button>
          <hr/>
        </Paper>
      </Grid>
    </Grid>
  </>;
};
